import React from 'react';


/***************************************************************************************** */
/***************************************************************************************** */
/***************************************************************************************** */
const Environment = "pro"; // Set the environment here. Production: "pro", Development: "dev" CHANGED
/***************************************************************************************** */
/***************************************************************************************** */
/***************************************************************************************** */



var apiURL = Environment == "dev" ? apiURL = "http://127.0.0.1:8080" : apiURL = "https://api.bevi.ai";

const ManageSubscriptionButton = ({ currentUser }) => {


  const handleManageSubscription = async () => {
    try {
      const response = await fetch(apiURL + '/api/create-customer-portal-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: currentUser.email }),
      });
      const data = await response.json();

      
      if (data.url) {
        console.log("URL IS")
        console.log(data.url);
        window.location.href = data.url;
      } else {
        console.log('Unable to load the customer portal.');
      }
    } catch (error) {
      console.error('Error:', error);
    
    }
  };

  return (
    <button type="button"  onClick={handleManageSubscription}>
      Manage Subscription
    </button>
  );
};

export default ManageSubscriptionButton;
