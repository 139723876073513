import React, { useState, useEffect } from 'react';


const TrendingVideos = ({ apiUrl, setYoutubeLink, fetchTranscriptAndAdvice, videoId, youtubeLink }) => {
  const [trendingVideos, setTrendingVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const[entered, setEntered] = useState("")

  useEffect(() => {
    const fetchTrendingVideos = async () => {
      
      try {
        const response = await fetch(`${apiUrl}/api/videos`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch trending videos');
        }

        const data = await response.json();
        setTrendingVideos(data);
      } catch (error) {
        console.error('Error fetching trending videos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTrendingVideos();
  }, []);

  const handleVideoClick = (youtubeUrl) => {
    setYoutubeLink(youtubeUrl);
    setEntered(youtubeUrl);
    
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    if (youtubeLink) {
      fetchTranscriptAndAdvice(); // Call this when youtubeLink state changes
    }
  }, [entered]);


  if (loading) {
    return null;
  }

  return (
    <div className="trending-videos-wrapper">
      
      <h4 className="trending-videos-title" style={{textShadow: '.1px .1px 1px rgba(0,0,0,0.2)', color: '#2F4F4F'}}>See What Others Are Watching: </h4>
      <div className="trending-videos-scroll" style={{
        display: 'flex',
        overflowX: 'auto',
        gap: '20px',
        padding: '20px',
        scrollBehavior: 'smooth'
      }}>
        {trendingVideos.map((video, index) => (
          <div 
            key={index} 
            className="trending-video-item"
            onClick={() => handleVideoClick(video.video_url)}
            style={{
              minWidth: '240px',
              flex: '0 0 auto'
            }}
          >
            <div className="thumbnail-container" style={{
              width: '100%',
              height: '144px',
              position: 'relative',
              overflow: 'hidden'
            }}>
              <img 
                src={video.medium_thumbnail} 
                alt={video.title} 
                className="trending-thumbnail"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  position: 'absolute',
                  top: 0,
                  left: 0
                }}
              />
              {video.duration && <span className="trending-duration">{video.duration}</span>}
            </div>
            <div className="trending-video-info" style={{
              padding: '8px 0'
            }}>
              <h4 className="trending-video-title" style={{ margin: '0 0 4px 0' }}>{video.title}</h4>
              <p className="trending-channel-name" style={{ margin: '0 0 2px 0' }}>{video.channel_name || 'Unknown Channel'}</p>
              <p className="trending-video-stats" style={{ margin: '0' }}>
                {video.published_date || 'Unknown date'}          
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrendingVideos;
