// Success.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Success = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to '/home' after 2 seconds
    const timer = setTimeout(() => {
      navigate('/home');
    }, 2000); // 2000 milliseconds = 2 seconds

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Thank you for your purchase!</h1>
      <p>You will be redirected to the home page shortly.</p>
    </div>
  );
};

export default Success;
