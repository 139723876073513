import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProgressLoader from './ProgressLoader'

const Comments = ({ videoId, apiURL }) => {
  const [analysis, setAnalysis] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchComments = async () => {
      if (!videoId) return;

      setLoading(true);
      setError(null);

      try {
        const response = await axios.post(`${apiURL}/api/get_youtube_comments`, { video_id: videoId });
        setAnalysis(response.data.analysis);
      } catch (err) {
        setError('Failed to fetch comments analysis');
        console.error('Error fetching comments:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchComments();
  }, [videoId, apiURL]);

  

  if (loading) return (
    <div style={{ ...styles.loadingContainer, paddingTop: '20px' }}>
      <ProgressLoader loading={loading} complete={false} />
    </div>
  );
  if (error) return <div style={{...styles.loadingError, color: '#e53e3e'}}>Error: {error}</div>;
  if (!analysis) return null;

  return (
    <>
      {/* <div>
        <h2 className="common-heading">Comments Analysis</h2>
      </div> */}
      <div style={styles.container}>
        <ul style={{ ...styles.summaryList, textAlign: 'left' }}>
          {analysis.summary.split('. ').map((sentence, index) => (
            <li key={index} style={{ ...styles.summaryItem, display: 'flex', alignItems: 'flex-start' }}>
              <span style={styles.bulletPoint}>{'\u2022'}</span>
              <span style={{ ...styles.summaryText, marginLeft: '8px' }}>{sentence.trim()}</span>
            </li>
          ))}
        </ul>
        <p style={styles.subDescription}></p>
        <ol style={styles.themeList}>
          {analysis.topics.map((topic, index) => (
            <li key={index} style={styles.themeItem}>
              <span style={styles.themeName}>{topic.topic}</span> – {topic.description}
            </li>
          ))}
        </ol>
        <p style={styles.conclusion}>{analysis.conclusion}</p>
      </div>
    </>
  );
};

const styles = {
  container: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    padding: '24px',
    marginTop: '2%',
    marginRight: '4px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1), 0 -4px 6px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
    lineHeight: '1.6',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    textAlign: 'left',
  },
  mainDescription: {
    fontSize: '18px',
    marginBottom: '20px',
    textAlign: 'left',
  },
  subDescription: {
    fontSize: '16px',
    marginBottom: '16px',
    textAlign: 'left',
    paddingBottom: '20px'
  },
  themeList: {
    paddingLeft: '20px',
    marginBottom: '20px',
    textAlign: 'left',
  },
  themeItem: {
    marginBottom: '16px',
    textAlign: 'left',
  },
  themeName: {
    fontWeight: 'bold',
  },
  conclusion: {
    fontSize: '18px',
    marginTop: '20px',
    textAlign: 'left',
  },
  loadingError: {
    padding: '32px',
    fontSize: '18px',
    color: '#4a5568',
    textAlign: 'left',
  },
};

export default Comments;