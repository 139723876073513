import React, { useEffect, useRef } from 'react';
import { useYouTubePlayer } from './YouTubePlayerContext';

const YouTubeVideo = ({ videoId }) => {
  const playerRef = useRef(null);
  const { setPlayer, setIsPlayerReady } = useYouTubePlayer(); // Adjust to also destructure setIsPlayerReady

  useEffect(() => {
    const initializePlayer = () => {
      if (!window.YT) {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(tag);

        window.onYouTubeIframeAPIReady = () => {
          createPlayer();
        };
      } else if (window.YT && window.YT.Player) {
        createPlayer();
      }
    };

    const createPlayer = () => {
      const newPlayer = new window.YT.Player(playerRef.current, {
        videoId,
        events: {
          'onReady': event => {
            setPlayer(event.target);
            setIsPlayerReady(true); // Update the ready state here
          },
        },
      });
    };

    initializePlayer();

    // Cleanup
    return () => {
      if (window.player && window.player.destroy) {
        window.player.destroy();
      }
    };
  }, [videoId, setPlayer, setIsPlayerReady]); // Add setIsPlayerReady to dependency array

  return (
    <div className="video-container-fixed video-responsive" style={{
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      overflow: 'hidden'
    }}>
      <div id="player" ref={playerRef}></div>
    </div>
  );
};

export default YouTubeVideo;
