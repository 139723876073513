import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext';
import { doSignOut } from '../../firebase/auth';
import logo from '../../Assets/images/brevy.svg';
import rabbit from '../../Assets/images/Rabbit.jpeg';
import { FaUserCircle, FaGlobe, FaSignOutAlt, FaCog, FaGift, FaClock } from 'react-icons/fa';
import ManageSubscriptionButton from '../ManageSubscriptionButton';
import '../../App.css';

const Header = ({ ipAdd, userSub, apiURL, urlCount }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userLoggedIn, currentUser } = useAuth();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const [totalTimeSaved, setTotalTimeSaved] = useState("");

  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState);
  };

  const handleNavigation = (path) => {
    setIsDropdownOpen(false);
    if (location.pathname === path) {
      window.location.reload();
    } else {
      navigate(path);
    }
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current && !dropdownRef.current.contains(event.target) &&
      buttonRef.current && !buttonRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    const fetchTotalTimeSaved = async () => {
      try {
        const response = await fetch(`${apiURL}/api/get_total_time_saved`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_email: currentUser.email }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch total time saved');
        }

        const data = await response.json();
        setTotalTimeSaved(data.total_time_saved);
      } catch (error) {
        console.error('Error:', error);
        setTotalTimeSaved("");
      }
    };

    if (currentUser && currentUser.email) {
      fetchTotalTimeSaved();
    }
  }, [currentUser, urlCount]);

  return (
    <nav className="brevy-nav">
      <div className="nav-bar-wrapper">
        <div className="nav-bar-main">
          <a href="/home">
            <div className="app-name">
              <img src={logo} alt="logo" />
              <span>Bevi</span>
            </div>
          </a>
          <div className="login-btns">
            {userLoggedIn ? (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: '0px', boxSizing: 'border-box', width: '100%' }}>
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                  <div className="time-saved-container">
                    <FaClock style={{ marginRight: '5px', color: '#4a5568' }} />
                    <span className="time-saved-label">You Have Saved: </span>
                    <span className="time-saved-value">{totalTimeSaved ? totalTimeSaved : '00:00:00'}</span>
                  </div>
                  <div
                    ref={buttonRef}
                    style={{
                      width: '35px',
                      height: '35px',
                      borderRadius: '50%',
                      overflow: 'hidden',
                      backgroundColor: '#ccc',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer'
                    }}
                    onClick={toggleDropdown}
                  >
                    <img src={currentUser.photoURL || rabbit} alt="user" style={{ width: '100%', height: 'auto' }} />
                  </div>

                  {isDropdownOpen && (
                    <div
                      ref={dropdownRef}
                      style={{
                        position: 'absolute',
                        top: '45px',
                        right: '0',
                        backgroundColor: 'white',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        zIndex: 1000
                      }}
                    >
                      <ul style={{ listStyleType: 'none', padding: '10px', margin: '0', minWidth: '250px' }}>
                        <li style={{ fontSize: "14.5px", padding: '4px 6px', cursor: 'default', display: 'flex', alignItems: 'center' }}>
                          <FaUserCircle style={{ marginRight: '10px' }} /> {currentUser.displayName || currentUser.email}
                        </li>
                        {/* <li style={{ fontSize: "14.5px", padding: '4px 6px', cursor: 'default', display: 'flex', alignItems: 'center' }}>
                          <FaGlobe style={{ marginRight: '10px' }} /> IP: {ipAdd}
                        </li> */}
                        {userSub.tier === "None" ? (
                          <li style={{ fontSize: "14.5px", padding: '4px 6px', cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => handleNavigation('/products')}>
                            <FaGift style={{ marginRight: '10px' }} /> Upgrade to a Plan
                          </li>
                        ) : (
                          null
                        )}

                        <li style={{ fontSize: "14.5px", padding: '4px 6px', cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => { doSignOut().then(() => { handleNavigation('/login') }) }}>
                          <FaSignOutAlt style={{ marginRight: '10px' }} /> Logout
                        </li>
                      </ul>

                      <div style={{ padding: '10px', color: 'blue', fontSize: '12px', textAlign: 'center', borderTop: '.5px solid #ccc' }}>
                        <Link to="/privacy-policy" onClick={() => setIsDropdownOpen(false)}>Privacy Policy</Link> |
                        <Link to="/terms-and-conditions" onClick={() => setIsDropdownOpen(false)}> Terms and Conditions</Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="login-btns">
                <button className="header-btn" onClick={() => handleNavigation('/login')}>
                  Login
                </button>
                <button className="gradient-button" onClick={() => handleNavigation('/register')}>
                  Register
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <style jsx>{`
        @media (max-width: 768px) {
          .time-saved-container {
            padding: 3px 6px !important;
          }
          .time-saved-label {
            display: none;
          }
          .time-saved-value {
            font-size: 10px !important;
          }
        }

        @media (max-width: 440px) {
          .time-saved-container {
            padding: 3px 6px !important;
          }
          .time-saved-label {
            display: none;
          }
          .time-saved-value {
            font-size: 10px !important;
          }
        }
      `}</style>
    </nav>
  );
};

export default Header;