import React, { useState, useEffect } from 'react';

const ProgressBar = ({ loading, complete }) => {
  const [filled, setFilled] = useState(0);
  const [loadingStatus, setLoadingStatus] = useState([true, true, true, true, true, true]);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    let timer;
    if (loading && !complete) {
      const incrementAmount = 95 / 400;
      timer = setInterval(() => {
        setFilled((currentFilled) => {
          if (currentFilled < 98) {
            return currentFilled + incrementAmount;
          } else {
            clearInterval(timer);
            return currentFilled;
          }
        });
      }, 100);
    } else if (complete) {
      clearInterval(timer);
      setFilled(100);
    }

    return () => clearInterval(timer);
  }, [loading, complete]);

  useEffect(() => {
    const timers = [
      setTimeout(() => updateLoadingStatus(0), 700),
      setTimeout(() => updateLoadingStatus(1), 4000),
      setTimeout(() => updateLoadingStatus(2), 5000),
      setTimeout(() => updateLoadingStatus(3), 9000),
      setTimeout(() => updateLoadingStatus(4), 12000),
      setTimeout(() => updateLoadingStatus(5), 70000),
    ];

    const hideLoaderTimer = setTimeout(() => setShowLoader(false), 100000);

    return () => {
      timers.forEach(clearTimeout);
      clearTimeout(hideLoaderTimer);
    };
  }, []);

  const updateLoadingStatus = (index) => {
    setLoadingStatus(prevStatus => {
      const newStatus = [...prevStatus];
      newStatus[index] = false;
      return newStatus;
    });
  };

  const steps = [
    "Transcribing your video",
    "Analyzing content",
    "Parsing key points",
    "Determining reliability",
    "Completing final checks",
    "Creating your notes"
  ];

  const completedSteps = loadingStatus.filter(status => !status).length;

  const containerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: 'rgb(255, 255, 255)',
    paddingTop: '20vh',
    paddingLeft: '2vw',
    paddingRight: '2vw',
    overflowY: 'auto',
    zIndex: 9999, // High z-index to cover everything else

  };

  const loaderStyle = {
    width: '97%',
    maxWidth: '28rem',
    padding: '1.5rem',
    backgroundColor: '#f8f8f8', // Changed to a slightly off-white color
    borderRadius: '0.5rem',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06), 4px 0 6px -1px rgba(0, 0, 0, 0.1), -4px 0 6px -1px rgba(0, 0, 0, 0.1)',
    marginBottom: '5vh',
  };

  const titleStyle = {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#1F2937',
    marginBottom: '2.5rem',
    paddingBottom: '1rem',
    textAlign: 'center',
  };

  const stepStyle = {
    display: 'flex',
    fontSize: '1.5rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
  };

  const stepTextStyle = {
    color: '#1B1B1B',
    fontSize: '0.9rem',
  };

  const progressBarStyle = {
    height: '0.5rem',
    backgroundColor: '#E5E7EB',
    borderRadius: '0.25rem',
    marginTop: '1.5rem',
    marginBottom: '1rem',
    overflow: 'hidden',
  };

  const progressStyle = {
    height: '100%',
    width: `${(completedSteps / steps.length) * 100}%`,
    backgroundImage: 'linear-gradient(45deg, #ff00cc, #3333ff, #00ccff)',
    backgroundSize: '200% 200%',
    animation: 'gradientAnimation 3s ease infinite',
    transition: 'width 4s ease-out',
  };

  const messageStyle = {
    fontSize: '0.875rem',
    color: '#353839',
    textAlign: 'center',
  };

  const checkmarkStyle = {
    width: '22px',
    height: '22px',
  };

  return (
    <div style={containerStyle}>
      <div style={loaderStyle}>
        {showLoader ? (
          <div >
            <h2 style={titleStyle}>Analyzing Your Video Content…</h2>
            <div>
              {steps.map((step, index) => (
                <div key={index} style={stepStyle}>
                  <span style={stepTextStyle}>{step}</span>
                  {loadingStatus[index] ? (
                    <div className="loader" style={{
                      border: '3px solid #f3f3f3',
                      borderTop: '3px solid #3498db',
                      borderRadius: '50%',
                      width: '20px',
                      height: '20px',
                      animation: 'spin 1s linear infinite',
                    }}></div>
                  ) : (
                    <svg style={checkmarkStyle} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12" cy="12" r="10" fill="#22C55E" />
                      <path d="M7 13l3 3 7-7" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  )}
                </div>
              ))}
            </div>
            <div style={progressBarStyle}>
              <div style={progressStyle}></div>
            </div>
            <p style={messageStyle}>
              Parsing video from the page… Long videos may take a few seconds to a minute to parse.
            </p>
          </div>
        ) : (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <div className="loader" style={{
              border: '4px solid #f3f3f3',
              borderTop: '4px solid #3498db',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              animation: 'spin 1s linear infinite',
            }}></div>
          </div>
        )}
      </div>
      <style jsx>{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        @keyframes gradientAnimation {
          0% { background-position: 0% 50%; }
          50% { background-position: 100% 50%; }
          100% { background-position: 0% 50%; }
        }
        @media (max-width: 640px) {
          .loader-container {
            padding-top: 2vh;
          }
          .loader-wrapper {
            padding: 1rem;
          }
          .step-text {
            font-size: 0.8rem;
          }
        }
      `}</style>
    </div>
  );
};

export default ProgressBar;