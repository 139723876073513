import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// const firebaseConfig = {
//   apiKey: "AIzaSyBLKI9Knfv_h0Gw_PEWhyupGNz5-3Nw-Ek",
//   authDomain: "brevy-52019.firebaseapp.com",
//   projectId: "brevy-52019",
//   storageBucket: "brevy-52019.appspot.com",
//   messagingSenderId: "1027148091804",
//   appId: "1:1027148091804:web:f3cd379343248b4535f859",
//   measurementId: "G-H3R9H1BFYC"
// };

const firebaseConfig = {
  apiKey: "AIzaSyBjxNFncxRFNkcjaMOELaPJYWYNd2WKejE",
  authDomain: "dynamic-heading-419922.firebaseapp.com",
  projectId: "dynamic-heading-419922",
  storageBucket: "dynamic-heading-419922.appspot.com",
  messagingSenderId: "884427243426",
  appId: "1:884427243426:web:7391fe0d78d328109a3cfb"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app)



export { app, auth };
