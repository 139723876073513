import axios from 'axios';




export const fetchIpAddress = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
    return response.data.ip;
  } catch (error) {
    console.error('Failed to fetch IP address:', error);
    return null;
  }
};


export async function getSubscriptionStatus(apiURL ,email) {


  try {
    console.log("webURL",apiURL)
    const response = await fetch(apiURL + '/api/get-subscription-status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch subscription status');
    }

    const data = await response.json();

    // Check if there's an error in the response
    if (data.error) {
      throw new Error(data.error);
    }

    return data;
  } catch (error) {
    console.error('Error fetching subscription status:', error.message);
    return { error: error.message };
  }
}
