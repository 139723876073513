import React, { useEffect, useRef } from 'react';
import { useAuth } from '../contexts/authContext';

const PricingTable = ({ customer_id }) => {
  const { currentUser } = useAuth();
  const scriptLoadedRef = useRef(false);

  useEffect(() => {
    if (!scriptLoadedRef.current) {
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/pricing-table.js';
      script.async = true;
      document.body.appendChild(script);

      scriptLoadedRef.current = true;
    }
  }, []);

  if (!currentUser || !currentUser.email) {
    return <div>Please log in to view subscription options.</div>;
  }

  return (
    <> 
    
      <h1 style={{ fontWeight: '400', color: 'rgb(71, 71, 71)', marginBottom: "0px !important"}} className="text-center">
        watch less, learn more
      </h1>
      <div className="linear-lines" style={{ marginBottom: '20px' }}>
                <div></div>
                <div className="middle-linear"></div>
                <div></div>
              </div>
      <h4 style={{ fontWeight: '400', color: 'rgb(71, 71, 71)' }} className="text-center mb-5 p-1">
      Your 3 free links weekly quota is up. Get more with a premium plan!      </h4>
      <div style={{ paddingBottom: '30px' }}>
        <stripe-pricing-table
          pricing-table-id="prctbl_1Q3Nm9Rr6MAHciMxec7qGSvZ"
          publishable-key="pk_test_51PsX6FRr6MAHciMx8XwxOO5IRDlUnBgwiMAZ5be9iT00JcmPf3ZxaU1WzckXamyqgNs8JtwrHctlahP7bN5gjDRt00dIaqH4DG"
          customer-email={currentUser.email}
          client-reference-id={customer_id}
          success-url="https://bevi.ai/success"
          cancel-url="https://bevi.ai"
        >
        </stripe-pricing-table>
      </div>
    </>
  );
};

export default PricingTable;
