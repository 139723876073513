import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import animationData from '../Assets/wait.json';

export default function ProgressLoader({ loading, complete }) {
  const [filled, setFilled] = useState(0);

  useEffect(() => {
    let timer;
    if (loading && !complete) {
      const incrementAmount = 95 / (400 / 2.4);
      timer = setInterval(() => {
        setFilled((currentFilled) => {
          if (currentFilled < 98) {
            return currentFilled + incrementAmount;
          } else {
            clearInterval(timer);
            return currentFilled;
          }
        });
      }, 100 / 3);
    } else if (complete) {
      clearInterval(timer);
      setFilled(100);
    }

    return () => clearInterval(timer);
  }, [loading, complete]);

  const getStageDescription = (filled) => {
    if (filled === 100) return "";
    if (filled >= 98) return "Creating Your Notes...";
    if (filled >= 91) return "Completing Final Checks...";
    if (filled >= 22) return "Parsing Key Points...";
    if (filled >= 11) return "Analyzing Comments...";
    if (filled === 0) return "Grabbing Comments...";
    return "Accessing Comments Section...";
  };

  const progressBarContainerStyle = {
    width: '30%',
    backgroundColor: '#f0f0f0',
    height: '20px',
    position: 'relative',
    margin: '0%',
    marginTop: '0px',
    borderRadius: '12px',
    overflow: 'hidden',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  };

  const centerAllItemsStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
  };

  return (
    <div style={centerAllItemsStyle}>
      <div className="progressbar" style={progressBarContainerStyle}>
        <div style={{
          height: "100%",
          width: `${filled.toFixed(0)}%`,
          background: 'linear-gradient(90deg, #6a11cb, #2575fc)',
          transition: "width 0.5s ease-out",
          boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.2)',
        }}></div>
        <span className="progressPercent" style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#fff',
          fontWeight: 'bold',
          fontSize: '16px',
          fontFamily: '"Courier New", Courier, monospace',
          textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
        }}>
          {filled.toFixed(0)}%
        </span>
      </div>
      <div style={{ textAlign: 'center', marginTop: '15px', color: '#333', fontWeight: '500' }}>
        {getStageDescription(filled)}
      </div>
    </div>
  );
}
