import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom"; 
import "../App.css";
import Progressbar from "./ProgressBar";
import YouTubeVideo from "./YouTubeVideo";
import { useYouTubePlayer } from "./YouTubePlayerContext";
import RelatedVideos from "./RelatedVideos";
import logo from "../Assets/images/brevy.svg";
import link from "../Assets/images/link.svg";
import pattern from "../Assets/images/pattern.png";
import left from "../Assets/images/left-pattern.png";
import time from "../Assets/images/time.svg";
import copy from "../Assets/images/copy.svg";
import copied from "../Assets/images/copied.svg";
import Summary from "./Summary";
import { useAuth } from "../contexts/authContext";
import { fetchIpAddress } from './Services/CaptureIP';
import Comments from "./Comments"
import Chatbot from './Chatbot';
import TrendingVideos from './TrendingVideos';
/***************************************************************************************** */
/***************************************************************************************** */

const userObj = {
  name: "",
  email: "",
  photo: "",
  display_name: "",
  email_verified: "",
  firebase_uid: "anonymous",
  phone_number: "",
  active: true,
  free_trial: true,
  usage_count: 0,
  signup_datetime: "",
  last_login_datetime: "",
}

const emptyUser = {
  uid: "anonymous",
  name: "",
  email: "",
  photo: "",
  display_name: "",
  email_verified: "",
  firebase_uid: "anonymous",
  phone_number: "",
  active: true,
  free_trial: true,
  usage_count: 0,
  signup_datetime: "",
  last_login_datetime: "",
  metadata_creationTime: "2023-01-01 00:00:00",
  metadata_lastSignInTime: "2023-01-01 00:00:00",
}
function MainContent({apiURL, webURL, userSub, urlCount, setUrlCount, setVideoId, videoId, userId}) {
  //const [userUrlCount, setUserUrlCount] = useState();
  const { currentUser : currentUsers } = useAuth();
  //////////console.log('here: ', currentUsers)
  const [ipAdd, setIpAddress] = useState('');
  const [complete, setComplete] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenComment, setIsOpenComment] = useState(false);
  const [showSummary, setShowSummary] = useState(true);
  const [relatedVids, setRelatedVids] = useState([]);
  const [showRelatedVideos, setShowRelatedVideos] = useState(false);
  const resultsRef = useRef(null);
  const chatbotRef = useRef(null);
  const [copyStatus, setCopyStatus] = useState({});

  const handleCopy = (textToCopy, index) => {
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        setCopyStatus((prevStatus) => ({
          ...prevStatus,
          [index]: true, // Mark this particular paragraph as copied
        }));

        setTimeout(() => {
          setCopyStatus((prevStatus) => ({
            ...prevStatus,
            [index]: false, // Reset the copied status after 2 seconds
          }));
        }, 2000);
      },
      (err) => {
        console.error('Failed to copy text: ', err);
      }
    );
  };

  const [youtubeLink, setYoutubeLink] = useState("");
  const [actionableAdvice, setActionableAdvice] = useState({});
  const [videoTitle, setVideoTitle] = useState("");
  const [videoThumbnails, setVideoThumbnails] = useState({ default: "", medium: "" });
  const [videoDuration, setVideoDuration] = useState("");
  const [videoKey, setVideoKey] = useState(0); // Initialize a state variable for the key
  const updateVideoKey = () => {
    setVideoKey((prevKey) => prevKey + 1);
  };
  const [summary, setSummary] = useState("");
  const [loading, setLoading] = useState(false);
  const [sentimentAnalysis, setSentimentAnalysis] = useState("");
  const [reliabilityScore, setReliabilityScore] = useState("");
  const [politicalLeaning, setPoliticalLeaning] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [mentionTopicContents, setMentionTopicContents] = useState({});
  const [adviceTopicsContents, setAdviceContentTopics] = useState([]);
  const [mentionTopics, setMentionTopics] = useState([]);
  const [adviceTopics, setAdviceTopics] = useState([]);
  //const [videoId, setVideoId] = useState("");
  const [ChannelName,  setChannelName] = useState("");  
  //const [clickbaitScore, setClickbaitScore] = useState("");
  const [user, setUser] = useState(userObj);
const [shareLink, setShareLink] = useState("");
//const [urlCount, setUrlCount] = useState(null);
const [chromaPath, setChromaPath] = useState(''); // New state for chroma path
const [processData, setProcessData] = useState(null);
const [transcript, setTranscript] = useState([]);
const [relatedVidsLoading, setRelatedVidsLoading] = useState(false);





var currentUser;
if(currentUsers){
  currentUser = currentUsers;
  //console.log('Current User is', currentUser.email)
} else {  
currentUser = emptyUser;
}

  //get user IP address
  useEffect(() => {
    const getIp = async () => {
      const ip = await fetchIpAddress();
      setIpAddress(ip);
    };

    getIp();
  }, [videoId]); 


  useEffect(() => {
    const fetchVideoDetails = async () => {
      try {
        const response = await fetch(apiURL + '/api/extract_video_details', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ video_id: videoId, user_email : currentUser.email ? currentUser.email : "" })
        });

        if (!response.ok) {
          throw new Error('Failed to fetch video details');
        }

        const data = await response.json();
       // console.log('Video Details:', data);

        // Update states with the fetched video details
        setVideoTitle(data.title);
        //setVideoDescription(data.description);
        setVideoThumbnails(data.thumbnails);
        setChannelName(data.channel_name)
        const convertDurationToSeconds = (duration) => {
          const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
          const hours = (parseInt(match[1]) || 0) * 3600;
          const minutes = (parseInt(match[2]) || 0) * 60;
          const seconds = parseInt(match[3]) || 0;
          return hours + minutes + seconds;
        };

        const durationInSeconds = convertDurationToSeconds(data.duration);
        setVideoDuration(durationInSeconds);
          
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (videoKey) {
      fetchVideoDetails();
    }
  }, [videoKey]);


  
  useEffect(() => {
    const formatUserData = (currentUser, ipAdd) => {
        // Define only the properties that should always sync with Firebase
        const userData = {
            user_ip: ipAdd || "",
            firebase_uid: currentUser.uid || "",
            name: currentUser.displayName || "",
            display_name: currentUser.displayName || "",
            email: currentUser.email || "",
            email_verified: currentUser.emailVerified || false,
            photo_url: currentUser.photoURL || "",
            phone_number: currentUser.phoneNumber || "",
            signup_datetime: currentUser.metadata ? currentUser.metadata.creationTime : null,
            last_login_datetime: currentUser.metadata ? currentUser.metadata.lastSignInTime : null,
        };

        // If both email and user_ip are empty, return null to prevent API call
        if (!userData.email && !userData.user_ip) {
            ////////console.log('Both email and user_ip are missing. No action taken.');
            return null;
        }

        // If signup_datetime or last_login_datetime are empty strings, set them to null
        if (userData.signup_datetime === '') {
            userData.signup_datetime = null;
        }
        if (userData.last_login_datetime === '') {
            userData.last_login_datetime = null;
        }

        // If it's the first sign-in, set default values
        if (userData.signup_datetime === userData.last_login_datetime) {
            userData.active = true;
            userData.free_trial = true;
            userData.usage_count = 0;
        }

        return userData;
    };

    // Function to upsert user data to the API
    const upsertUserData = async (formattedUserData) => {
        if (!formattedUserData) return; // Skip API call if data is null

        try {
            const response = await fetch(apiURL + '/api/user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formattedUserData),
            });

            // Check if the request was successful
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            ////////console.log('Upsert successful', data);
        } catch (error) {
            console.error('There was an error upserting the user:', error);
        }
    };


      //////////console.log(ipAdd)
      const formattedUserData = formatUserData(currentUser, ipAdd); //Pass IP address here. If IP present, and no email, give 3 tries. If email is present, 5 API calls. If email is not present, 3 API calls.
      ////////console.log('Formatted User Data', formattedUserData)
      upsertUserData(formattedUserData);
      setUser(formattedUserData); // Optionally update state as well
   
  }, [currentUser, complete, ipAdd]); 

  const navigate = useNavigate(); // Initialize the navigate function

  // Inside your App component or wherever you need to control the player
  const { player, isPlayerReady } = useYouTubePlayer(); // Now also destructure isPlayerReady

  const seekTo = (timeInSeconds) => {
    ////////////console.log("seek to called 1");
    if (player && isPlayerReady && player.seekTo) {
      ////////////console.log("seek to called 2");
      player.seekTo(timeInSeconds);
      if (player.getPlayerState() !== 1) {
        // YT.PlayerState.PLAYING
        ////////////console.log("seek to called 3");
        player.playVideo();
      }
    } else {
      ////////////console.log("Player is not ready or seekTo is undefined.");
    }
  };
 


  const fetchTranscriptAndAdvice = async () => {
    // stripe account logic goes here!
    console.log("Fetching transcript and advice");
    if (urlCount >= 3 && !currentUsers) {
      //console.log(currentUsers);
      navigate('/register'); // Redirect to register page if user is not authenticated
      return;
    }

    if (urlCount >= 3 && userSub == "Free") {
      //console.log(currentUsers);
      navigate('/products'); // Redirect to products page if user is on Free tier
      return;
    }
    if (urlCount > 5 && userSub == "Casual") {
      //console.log(currentUsers);
      navigate('/products'); // Redirect to products page if user is on Casual tier
      return;
    }
    if (urlCount > 13 && userSub == "Express") {
      //console.log(currentUsers);
      navigate('/products'); // Redirect to products page if user is on Express tier
      return;
    }

  
    setErrorMessage("");
    resetAdviceState();

   
    setComplete(false);
    const youtubeUrlRegex =
    /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|playlist\?list=)|youtu\.be\/)([a-zA-Z0-9_-]+)(?:\?[^#\s]*|&[^#\s]*|#.*)?$/;

    const mobileYoutubeUrlRegex =
    /^(https?:\/\/)?(m\.youtube\.com\/watch\?v=)([a-zA-Z0-9_-]+)(?:\?[^#\s]*|&[^#\s]*|#.*)?$/;
    let match = youtubeLink.trim().match(youtubeUrlRegex);
    if (match == null) match =  youtubeLink.trim().match(mobileYoutubeUrlRegex);

 
    if (!youtubeLink.trim()) {
      setErrorMessage("No link was pasted :(");
      return;
    }

    if (!youtubeUrlRegex.test(youtubeLink.trim())) {
      setErrorMessage("Please enter a valid YouTube link 😊");
      return;
    }
    if (youtubeLink.trim() || youtubeUrlRegex.test(youtubeLink.trim())) {
      setLoading(true);
    }
    setShowRelatedVideos(true)
    if(window.innerWidth >= 768){
      setIsOpen(true)
    }
    

      ////////////console.log("Match", match);
      if (match && match[5]) {
        // match[5] contains the video ID or playlist ID
        const videoID = match[5];
        
        setVideoId(videoID);

    

        updateVideoKey();

      } else if(match && match[3]) {

        const videoID = match[3];
        
        setVideoId(videoID);

    

        updateVideoKey();

      }
      
      
      else {
        console.log("here? ")
   
        setErrorMessage("Please enter a valid YouTube link 😊");
        return;
      } 
      
    
      //if Null, then set to anonymous
        ////console.log("here: ", currentUsers)
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            videoTitle: videoTitle,
            youtube_url: youtubeLink,
            firebase_uid: currentUsers ? currentUser.uid : "anonymous",
            ip: ipAdd,
            user_id: userId
          }),
        };
        /////console.log('Request Options', requestOptions)
        // Fetching transcript and processing video as per the original setup
        try {
          setLoading(true);

          const processRes = await fetch(apiURL + `/api/process_video`, requestOptions);
          if (!processRes.ok) {
            setShowRelatedVideos(false);
            throw new Error("🎬 Oops! We couldn't find a transcript for this video. Try another one! 🎥");
          }
          
          const processData_1 = await processRes.json();
          console.log('=== Process Video Response ===');
          console.log('Full processData:', processData_1);
          console.log('Summary:', processData_1.summary);
          console.log('=== End Process Video Response ===');

          setProcessData(processData_1); // Store the entire response
          
          // Updating the handling logic here to dynamically process the structured JSON
          function extractTopicsAndContents(data, objectName, objectName2) {
            // Initialize arrays to hold the topics and their contents
            let topics = [];
            let topicContents = [];
      
            const targetObject = data[objectName] || data[objectName2];
            if (targetObject) {
              // Extract topics and contents from the target object
              for (const [key, value] of Object.entries(targetObject)) {
                topics.push(key);
                topicContents.push(value);
              }
            }

            return { topics, topicContents };
          }

        

      //mentions
      function extractMentionTopicsAndContents(data, objectName, objectName2) {
        // Initialize arrays to hold the topics and their contents
        let Mtopics = [];
        let MtopicContents = [];
 
        // Check if the specified object name exists in the data
        const targetObject = data[objectName] || data[objectName2];
        if (targetObject) {
          // Extract topics and contents from the target object
          for (const [key, value] of Object.entries(targetObject)) {
            Mtopics.push(key);
            MtopicContents.push(value);
          }
        }

        return { Mtopics, MtopicContents };
      }

      // Call the function for "all actionable advices"
      const { topics, topicContents } = extractTopicsAndContents(
        processData_1.summary,
        "all actionable advices",
        "all_actionable_advices"
      );
      //debug

      setAdviceTopics(topics || []);
      setAdviceContentTopics(topicContents || {});
      // Call the function for "all actionable advices"

      const { Mtopics, MtopicContents } = extractMentionTopicsAndContents(
        processData_1.summary,
        "all_mentions",
        "all mentions"
      );
  

      //console.log(processData)
      setMentionTopics(Mtopics || []);
      setMentionTopicContents(MtopicContents || {});
      setSummary(processData_1.summary.summary || processData_1.summary.key_conclusions || processData_1.summary || null);
      setActionableAdvice(processData_1.summary["all actionable advices"] || processData_1.summary["all_actionable_advices"] || {});
      setSentimentAnalysis(processData_1.summary.sentiment_analysis || null);
     // setClickbaitScore(processData_1.summary.click_bait_score || null);
      setReliabilityScore(processData_1.summary.reliability_score || null);
      setPoliticalLeaning(processData_1.summary.political_leaning || null);
      setComplete(true);
      setLoading(false);
      //////console.log('URL Count:', processData.url_count)
      //setUrlCount(processData_1.url_count)
      //////console.log('Chroma Path:', processData)
      setChromaPath(processData_1.chroma_path);
      ///////////////////////////////////////////////////////////
      const link_id = processData_1.link_id;
      setShareLink(webURL + `/summary/${link_id}`);
      ///////////////////////////////////////////////////////////
    } catch (error) {
      console.error("There was an error processing the video:", error);
      setErrorMessage(
        error.message || "There was an error processing the video."
      );
    } finally {
      setLoading(false);
      // Ensure summary is shown and other views are hidden when loading completes
      setShowSummary(true);
      setIsOpen(false);
      setIsOpenComment(false);
    }
  };

  const resetAdviceState = () => {
    setSummary("");
    setTranscript([]);
    setActionableAdvice({});
    setSentimentAnalysis("");
    setReliabilityScore("");
    setPoliticalLeaning("");
    setMentionTopics([]);
  };

  const [buttonText, setButtonText] = useState('Share 🚀');

  const copyShareLink = () => {
    navigator.clipboard.writeText(shareLink).then(
      () => {
        setButtonText('Copied'); // Change button text
        // Reset button text after 2 seconds
        setTimeout(() => {
          setButtonText('Share 🚀');
        }, 2000);
      },
      (err) => {
        console.error('Failed to copy share link: ', err);
      }
    );
  };

  function formatDuration(totalSecondsFloat) {
    const totalSeconds = Math.floor(totalSecondsFloat); // Convert to whole seconds if needed
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    // Format minutes and seconds with leading zeros
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    // Include hours in the output only if it's more than 0
    if (hours > 0) {
      return `${hours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      return `${formattedMinutes}:${formattedSeconds}`;
    }
  }

  //console.log("Video Title:", videoTitle);
  //console.log("Video Description:", videoDescription);
  //console.log("Video Thumbnails:", videoThumbnails);
  //console.log("Video Duration:", videoDuration);



console.log("showRelatedVideos", showRelatedVideos);
console.log("showSummary", showSummary);

  useEffect(() => {
    let timeoutId;
    
    const handleScroll = () => {
      clearTimeout(timeoutId);
      
      timeoutId = setTimeout(() => {
        const videoContainer = document.querySelector('.sticky-video-container');
        const rightColumn = document.querySelector('.right-column');
        const isMobile = window.innerWidth <= 768;
        
        if (videoContainer && rightColumn && !isOpen) {
          const pageHeight = document.documentElement.scrollHeight - window.innerHeight;
          const scrollPosition = window.scrollY;
          const scrollPercentage = (scrollPosition / pageHeight) * 100;
          const isCurrentlyScrolled = videoContainer.classList.contains('scrolled');

          if (scrollPercentage > 6 && !isCurrentlyScrolled) {
            // Different behavior for mobile and desktop
            if (isMobile) {
              videoContainer.classList.add('scrolled-mobile');
            } else {
              videoContainer.classList.add('scrolled');
              rightColumn.classList.add('has-sticky-video');
            }
          } else if (scrollPercentage <= 6 && (isCurrentlyScrolled || videoContainer.classList.contains('scrolled-mobile'))) {
            videoContainer.classList.remove('scrolled');
            videoContainer.classList.remove('scrolled-mobile');
            rightColumn.classList.remove('has-sticky-video');
          }
        }
      }, 1);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutId);
    };
  }, [isOpen]);

  useEffect(() => {
    // Only proceed if all necessary data is available and loading is complete
    if (
      summary && 
      videoId && 
      youtubeLink && 
      processData && 
      !loading && 
      complete && 
      // Check that key summary components are available
      (mentionTopics.length > 0 || adviceTopics.length > 0)
    ) {
      const persistVideoDataToDatabase = async () => {
        try {
          const videoData = {
            firebase_uid: currentUsers?.uid || 'anonymous',
            video_url: youtubeLink,
            ip: ipAdd,
            video_summary_json: processData.summary,
            title: videoTitle || '',
            default_thumbnail: videoThumbnails?.default || '',
            medium_thumbnail: videoThumbnails?.medium || '',
            channel_name: ChannelName || '',
            published_date: new Date().toISOString(),
            video_description: '' || '',
            user_id : userId
          };

          console.log('=== Storing Video Data ===');
          console.log('Video Data being stored:', videoData);
          console.log('Original processData.summary:', processData.summary);
          console.log('=== End Storing Video Data ===');

          const response = await fetch(apiURL + '/api/youtube/insert', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(videoData)
          });

          const responseData = await response.json();
          
          if (!response.ok) {
            console.error('Failed to store video data:', {
              status: response.status,
              statusText: response.statusText,
              data: responseData
            });
          } else {
            console.log('Successfully stored video data:', responseData);
          }
        } catch (error) {
          console.error('Error storing video data:', error);
        }
      };

      persistVideoDataToDatabase();
    }
  }, [summary, loading, complete, mentionTopics, adviceTopics]); // Added dependencies

  useEffect(() => {
    const extractVideoIds = async () => {
      if (!youtubeLink || !summary || loading || !complete || 
          (!mentionTopics.length && !adviceTopics.length)) {
        return;
      }

      try {
        setRelatedVidsLoading(true); // Set loading to true before API call
        const response = await fetch(apiURL + '/api/extract_video_ids', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ youtube_url: youtubeLink.trim() })
        });

        if (!response.ok) {
          throw new Error('Failed to extract video IDs');
        }

        const data = await response.json();
        console.log('Extracted Video IDs:', JSON.stringify(data));
        setRelatedVids(data);
        setShowRelatedVideos(true);
      } catch (error) {
        console.error('Error extracting video IDs:', error);
      } finally {
        setRelatedVidsLoading(false); // Set loading to false after API call completes
      }
    };

    extractVideoIds();
  }, [summary, loading, complete, mentionTopics, adviceTopics, youtubeLink, apiURL]);



  return (
    <div className={`App ${isOpen ? 'chatbot-open' : ''}`}>
      {!summary && (
        <>
          <div className="app-name">
            <img src={logo} alt="logo"></img>Bevi
          </div>
          <div className="main-details">
          <h1>Video Summary</h1>
            <p>
              Save time on watching YouTube videos. Break down YouTube videos into
              parts and get key takeaways.
            </p>
            <div className="linear-lines">
              <div></div>
              <div className="middle-linear"></div>
              <div></div>
            </div>
          </div>
          <img src={pattern} alt="right-pattern" className="right-pattern"></img>
          <img src={left} alt="left-pattern" className="left-pattern"></img>
        </>
      )}

      <div className="summarize-section">
        <div className="content">
          <img src={link} alt="link" className="link"></img>
          <input
            type="text"
            id="youtubeLink"
            name="youtubeLink"
            value={youtubeLink}
            onChange={(e) => setYoutubeLink(e.target.value)}
            className="youtube-input"
            placeholder="Paste YouTube video URL here! 🐇 "
          />
          <button onClick={fetchTranscriptAndAdvice} className="ggradient-button">
            Summarize
          </button>
        </div>
      </div>

      {!videoKey && (
        <TrendingVideos 
          apiUrl={apiURL}
          setYoutubeLink={setYoutubeLink}
          fetchTranscriptAndAdvice={fetchTranscriptAndAdvice}
          videoId={videoId}
          youtubeLink={youtubeLink} 
        />
      )}

      <div className="app-container">
        <div className="center-column">
          <div className="custom-container">
            {loading ? (
              <Progressbar loading={loading} complete={complete} />
            ) : (
              <div></div>
            )}

            {errorMessage && (
              <div className="error-popup">
                {errorMessage}
              </div>
            )}

            {(summary ||
              Object.keys(actionableAdvice).length > 0 ||
              sentimentAnalysis ||
              reliabilityScore ||
              politicalLeaning) && (
              <div className="results-section" ref={resultsRef}>
                <div style={{ paddingTop: 0 }}>
                  <div className="sticky-video-container" style={{ borderRadius: '8px', overflow: 'hidden' }}>
                    <YouTubeVideo key={videoKey} videoKey={videoKey} videoId={videoId} />
                    <div style={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center', 
                      margin: '8px 0',
                      padding: '0 2px' 
                    }}>
                      <div className="video-title" style={{ 
                        fontSize: '18px', 
                        fontWeight: 'bold', 
                        textAlign: 'left',
                        flex: 1 // Takes up available space
                      }}>
                        {videoTitle}
                      </div>
                      {sentimentAnalysis && (
                        <div style={{ 
                          fontSize: '12px',
                          color: '#666',
                          marginLeft: '20px' // Space between title and sentiment
                        }}>
                          <b>Video Tone:</b> {sentimentAnalysis}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="sents laptop-only">
                    <div>
                      <p><b>Video Tone:</b>{sentimentAnalysis}</p>
                    </div>
                    <div>
                      <p><b>Fact Score:</b> {reliabilityScore}/10</p>
                    </div>
                  </div> */}
                  <style jsx>{`
                    @media (max-width: 1200px) {
                      .laptop-only {
                        display: none;
                      }
                    }
                  `}</style>

                  <div className="segmented-controls">
                    <button 
                      onClick={copyShareLink} 
                      className={buttonText === 'Copied' ? 'active' : ''}
                    >
                      {buttonText}
                    </button>

                    <button
                      className={isOpen ? 'active' : ''}
                      onClick={() => {
                        setIsOpen(!isOpen);
                        setShowSummary(false);
                        setIsOpenComment(false);
                        
                        // Increased scroll amount to 56% of viewport height
                        setTimeout(() => {
                          window.scrollBy({
                            top: window.innerHeight * 0.56, // 56% of viewport height
                            behavior: 'smooth'
                          });
                        }, 100);
                      }}
                    >
                      Chatbot
                    </button>

                    <button
                      className={showSummary && !isOpen && !isOpenComment ? 'active' : ''}
                      onClick={() => {
                        setShowSummary(true);
                        setIsOpenComment(false);
                        setIsOpen(false);
                        
                        // Scroll to top smoothly
                        window.scrollTo({
                          top: window.innerHeight * 0.1, // Scroll to 10% from top
                          behavior: 'instant'
                        });
                      }}
                    >
                      Summary
                    </button>

                    <button
                      className={isOpenComment ? 'active' : ''}
                      onClick={() => {
                        setIsOpenComment(!isOpenComment);
                        setShowSummary(false);
                        setIsOpen(false);
                        // Scroll down to comments with a slight delay
                        setTimeout(() => {
                          const commentsElement = document.querySelector('.comments-section');
                          if (commentsElement) {
                            commentsElement.scrollIntoView({ 
                              behavior: 'smooth', 
                              block: 'start' 
                            });
                          }
                        }, 100);
                      }}
                    >
                      Comments
                    </button>
                  </div>

                  {isOpen && (
                    <div className="static-chatbot" ref={chatbotRef}>
                      <Chatbot
                        videoURL={youtubeLink}
                        videoId={videoId}
                        chromaPath={chromaPath}
                        isOpen={isOpen}
                        player={player}
                        isPlayerReady={isPlayerReady}
                        setIsOpen={() => setIsOpen(false)}
                      />
                    </div>
                  )}

                  {!isOpen && isOpenComment && (
                    <Comments videoId={videoId} apiURL={apiURL}/>
                  )}

                  {!isOpen && !isOpenComment && showSummary && (
                    <div className="inner-results">
                      <div className="details-container">
                        <img
                          src={copyStatus['summary'] ? copied : copy}
                          alt="copy"
                          className="copyy"
                          onClick={(e) => {
                            e.stopPropagation();
                            const textToCopy = `${summary}`;
                            handleCopy(textToCopy, 'summary');
                          }}
                        />
                        <Summary summary={summary} />
                      </div>
  
                      {adviceTopics.length > 0 && (
                        <h2 className="common-heading">Recommendations</h2>
                      )}
  
                      {adviceTopics &&
                        adviceTopics.map((topic, index) => (
                          <React.Fragment key={index}>
                            <div key={index} className="details-container">
                              <h3 className="common-sub">
                                {topic.charAt(0).toUpperCase() + topic.slice(1)}
                              </h3>
                              {adviceTopicsContents[index] &&
                              adviceTopicsContents[index].length > 0 ? (
                                adviceTopicsContents[index].map(
                                  (content, contentIndex) => (
                                    <div className="paragraph-data" key={contentIndex}>
                                      <div
                                        className="content-top-details"
                                        onClick={() => {
                                          seekTo(content.start_time);
                                          
                                        }}
                                      >
                                        <img
                                          src={time}
                                          alt="time"
                                          className="time"
                                        ></img>
                                        <strong>
                                          <span style={{ color: '#531fff' }}>
                                            {formatDuration(content.start_time)}
                                          </span>
                                          {" " + content.name}:
                                        </strong>
                                        <img
                                          src={
                                            copyStatus[`${index}-${contentIndex}`]
                                              ? copied
                                              : copy
                                          }
                                          alt="copy"
                                          className="copy"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            const textToCopy = `${content.name}: ${content.description}`;
                                            handleCopy(
                                              textToCopy,
                                              `${index}-${contentIndex}`
                                            );
                                          }}
                                        />
                                      </div>
                                      <p className="parad">{content.description}</p>
                                    </div>
                                  )
                                )
                              ) : (
                                <p>No content available for this topic.</p>
                              )}
                            </div>
                          </React.Fragment>
                        ))}
  
                      {mentionTopics.length > 0 && (
                        <h2 className="common-heading">Highlights</h2>
                      )}
                      {mentionTopics &&
                        mentionTopics.map((topic, index) => (
                          <React.Fragment key={index}>
                            <div key={index} className="details-container">
                              <h3 className="common-sub">
                                {topic.charAt(0).toUpperCase() + topic.slice(1)}
                              </h3>
                              {mentionTopicContents[index] &&
                              mentionTopicContents[index].length > 0 ? (
                                mentionTopicContents[index].map(
                                  (content, contentIndex) => (
                                    <div className="parad" key={contentIndex}>
                                      <div
                                        className="content-top-details"
                                        onClick={() => {
                                          seekTo(content.start_time);
                                       
                                        }}
                                      >
                                        <img
                                          src={time}
                                          alt="time"
                                          className="time"
                                        ></img>
                                        <strong>
                                          <span style={{ color: '#531fff' }}>
                                            {formatDuration(content.start_time)}
                                          </span>
                                          {" " + content.name}:
                                        </strong>
                                        <img
                                          src={
                                            copyStatus[`${index}-${contentIndex}`]
                                              ? copied
                                              : copy
                                          }
                                          alt="copy"
                                          className="copy"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            const textToCopy = `${content.name}: ${content.description}`;
                                            handleCopy(
                                              textToCopy,
                                              `${index}-${contentIndex}`
                                            );
                                          }}
                                        />
                                      </div>
                                      {content.description}
                                    </div>
                                  )
                                )
                              ) : (
                                <p>No content available for this topic.</p>
                              )}
                            </div>
                          </React.Fragment>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        
        
        <div className="right-column">
          {showRelatedVideos && (
            <div className="related-videos-container">           
              <RelatedVideos 
                relatedVids={relatedVids} 
                setYoutubeLink={setYoutubeLink} 
                youtubeLink={youtubeLink} 
                videoId={videoId}
                fetchTranscriptAndAdvice={fetchTranscriptAndAdvice} 
                setRelatedVids={setRelatedVids}
                apiUrl={apiURL}
                showRelatedVideos={showRelatedVideos}
              />
            </div>
          )}
        </div>  
      </div>
    </div>
  );
}

export default MainContent;