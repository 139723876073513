import React, { useState } from 'react';

const Summary = ({ summary }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  let truncateCount = 0;
  let points = 0
if (summary != null){
  console.log(JSON.stringify(summary))
  const summ =  summary.replace(/(?<=[a-zA-Z])-(?=[a-zA-Z])/g, ' ');

  
  
  points = summ.split('-').filter(point => point.trim() !== '');
  truncateCount = 4;
}

  const containerStyle = {
    textAlign: 'left',
    maxWidth: '100%',
    margin: '0 auto',
  };

  const ulStyle = {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  };

  const liStyle = {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'flex-start',
  };

  const bulletPointStyle = {
    marginRight: '8px',
    fontSize: '2em', // Adjusted size
    lineHeight: '0.5', // Adjusted to align with text
    color: '#4B4B4B', // Dark pencil color
  };

  const textStyle = {
    flex: 1,
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  };

  const buttonStyle = {
    background: 'none',
    border: 'none',
    color: 'rgb(80 80 80)', // Gray color
    cursor: 'pointer',
    padding: '5px 10px',
    fontSize: '0.9em',
  };

  const renderPoints = (pointsToRender) => {
    return pointsToRender.map((point, index) => (
      <li key={index} style={liStyle}>
        <span style={bulletPointStyle}>{'\u2022'}</span> {/* New bullet point character */}
        <span style={textStyle}>{point.trim()}</span>
      </li>
    ));
  };

  return (
    <div style={containerStyle}>
      <ul style={ulStyle}>
        {isExpanded || points.length <= truncateCount
          ? renderPoints(points)
          : renderPoints(points.slice(0, truncateCount))}
      </ul>
      {points.length > truncateCount && (
        <div style={buttonContainerStyle}>
          <button onClick={toggleExpanded} style={buttonStyle}>
            {isExpanded ? (
              <>
           
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: '5px', verticalAlign: 'middle' }}>
                  <polyline points="18 15 12 9 6 15"></polyline>
                </svg>
              </>
            ) : (
              <>
              
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: '5px', verticalAlign: 'middle' }}>
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              </>
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default Summary;