import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import { AuthProvider } from './contexts/authContext';  // Import AuthProvider
import { YouTubePlayerProvider } from './Components/YouTubePlayerContext'; // Adjust the path as necessary

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>  {/* Wrapping the app in AuthProvider */}
    <YouTubePlayerProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </YouTubePlayerProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
